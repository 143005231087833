<template>
  <section class="content">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row mb-2" style="row-gap: 0.5rem;">
          <div class="col-12">
            <h6 class="mb-0">Filter Pencarian</h6>
          </div>
          <div class="col-12 col-sm-auto">
            <select class="form-control" @change="dataType($event)">
              <option value="pickupTime">Dibuat Antara</option>
              <option value="updatedOn">Update Antara</option>
            </select>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="input-group">
              <input
                type="text"
                ref="daterange"
                class="form-control"
                style="border-right: 0"
              />
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  style="background-color: #fff"
                >
                  <span><span class="fa fa-calendar"></span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-info dropdown-icon dropdown-toggle"
              @click="selectStatus"
            >
              Status
            </button>
          </div>
          <div class="col-auto ml-auto">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsivex">
          <table class="table table-hover" ref="tableuser" id="tblriwayat">
            <thead>
              <tr>
                <th>NO. ORDER</th>
                <th>NO. RESI</th>
                <th>PENGIRIM</th>
                <th>KURIR</th>
                <th>PENERIMA</th>
                <th>TELEPON</th>
                <th>TUJUAN</th>
                <th>TANGGAL</th>
                <th>BERAT(KG)</th>
                <th>COD</th>
                <th>NILAI BARANG</th>
                <th>NILAI COD</th>
                <th>ONGKIR AWAL</th>
                <th>DISKON</th>
                <th>ONGKIR + PPN</th>
                <th>ASURANSI</th>
                <th>COD FEE + PPN</th>
                <th>TOTAL BIAYA</th>
                <th>COD DITERIMA</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <Historyfilter :show="show" :sTicket="false" :sOther="false" v-model="filter" v-on:change="updateFilter" />
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import moment from "moment";

export default {
  name: "Riwayat",
  data() {
    return {
		dateStart: moment().startOf("month").format("YYYY-MM-DD"),
		dateEnd: moment().format("YYYY-MM-DD"),
		errors: [],
		method: "",
		roles: "",
		groups: [],
		formTitle: "Tambah User",
		form: {
        name: "",
        username: "",
        email: "",
    	},
		downloading: false,
		show: false,
		filter: {},
		dt1: moment().startOf("month"),
		dt2: moment(),
    	data_type: "pickupTime",
		disabled: false,
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    Historyfilter,
  },
  watch:{
    $route (to, from){
      if(to.path === "/payment-shipper/all")
      {
        sessionStorage.filterData='';
		sessionStorage.filterStatus = '';
        this.filter = {};
        this.table.api().ajax.reload();
      }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

	if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

	if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;
    this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
    this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = this.filter;
      // filter.d1 = this.dateStart;
      // filter.d2 = this.dateEnd;
      filter.datatype = this.data_type;

      var data = Object.keys(filter)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        )
        .join("&");
      authFetch("/report/payment/excell", {
        method: "POST",
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    updateFilter: function (e) {
      this.filter = e;
      console.log(this.filter);
      this.table.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
	  this.filter.datatype = this.data_type;
    //   this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
    selectStatus: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
          this.$router.push({
            path: "/payment-shipper/detail/" + e.target.dataset.id,
          });
        }
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    console.log("logo", self.getKurirLogo("jne"));
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.table = createTable(e.tableuser, {
      title: "Data Riwayat",
      roles: this.$route.params.roles,
      ajax: "/payment/shipper/riwayat",
      frame: false,
      scrollX: true,
      autoWidth: false,
      toolbarEl: e.toolbar,
      lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
      lengthChange: true,
      filter: true,
      dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      paramData: function (d) {
        // d.d1 = self.dateStart;
        // d.d2 = self.dateEnd;
        // d.datatype = self.data_type;
		var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
      columns: [
        { data: "order_code" },
        { data: "billNo" },
        {
          data: "gudang",
          title: isShipper ? "ASAL GUDANG" : "Shipper",
          render: function (data, type, row, meta) {
            return data;
          },
        },
        {
          data: "courierCode",
          visible: isKurir ? false : true,
          render: function (data, type, row, meta) {
            var logo = data.toLowerCase();
            return '<img height="16" src="' + self.getKurirLogo(logo) + '" />';
          },
        },
        {
          data: "destName",
          title: "PENERIMA",
          render: function (data, type, row, meta) {
            return row.destName;
          },
        },
        { data: "destTel" },
        {
          data: "destCity",
          render: function (data, type, row, meta) {
            return row.kecamatan + ", " + row.destCity;
          },
        },
        {
          data: "pickupTime",
          render: function (data, type, row, meta) {
            return data;
          },
        },
        { data: "weight" },
        { data: "codStatus",
          render: function (data, type, row, meta) {
            if(data == 1)
            return '<span class="badge badge-success">YA</span>';
            else if (data == 0)
            return '<span class="badge badge-warning">TIDAK</span>';
          }, 
        },
        {
          data: "item_value",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(row.item_value);
          },
        },
        {
          data: "codValue",
          render: function (data, type, row, meta) {
            let nilaicod = data;
            if (nilaicod == "0.00") {
              return 0;
            } else {
              return "Rp" + formatCurrency(nilaicod);
            }
          },
        },
        {
            
          data: "ongkir_dasar",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(row.ongkir_dasar);
            // return "banyak";
          },
        },
        {
            
          data: "disc",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(row.disc);
          },
        },
        {
            
            data: "nilai_ongkir",
            render: function (data, type, row, meta) {
                return "Rp" + formatCurrency(row.nilai_ongkir);
            },
        },
        {
          data: "insurance_fee",
          render: function (data, type, row, meta) {
            let insurance = '';
            if(row.insurance_type == 0){
              insurance = '-';
            } else if(row.insurance_type == 1 && row.insurance_fee < 1){
              insurance = 'FREE';
            } else if(row.insurance_type == 1 && row.insurance_fee > 0) {
              insurance = "Rp" + formatCurrency(row.insurance_fee);
            }
            
            return insurance;
          },
        },
        {
          data: "fee_ppn",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(data);
          },
        },
        {
          data: "totalbiaya",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(data);
          },
        },
        {
          data: "grand_total",
          render: function (data, type, row, meta) {
            return "Rp" + formatCurrency(data);
            // return "-";
          },
        },
        {
					data: "pickupStatus",
					render: function (data, type, row, meta) {
						if (row.cancel == 1)
							return '<span class="badge badge-danger">Cancel</span>';
						else if (row.pickupStatus == 0)
							return '<span class="badge badge-inverse">DRAFT</span>';
						else {
							if (row.trackingStatus == 0)
                if(row.order_type == 1){
                  return '<span class="badge badge-secondary">Menunggu Drop</span>';
                } else {
                  return '<span class="badge badge-secondary">Menunggu Pickup</span>';
                }
							else if (row.trackingStatus == 1)
								return '<span class="badge badge-warning">Proses</span>';
							else if (row.trackingStatus == 2)
								return '<span class="badge badge-success">Terkirim</span>';
							else if (row.trackingStatus == 3)
								return '<span class="badge badge-warning">Proses Retur</span>';
							else if (row.trackingStatus == 4) {
								if (row.retStatus == 1) return '<span class="badge badge-success">Retur Diterima</span>';
								else if (!row.retStatus && !row.retClaim)
									return '<span class="badge badge-info">Retur Diserahkan</span>';
								else if (row.retClaim == 1)
									return '<span class="badge badge-danger">Retur Diclaim</span>';
								else if (row.retClaim == 2)
									return '<span class="badge badge-success">Retur Claim disetujui</span>';
								else if (row.retClaim == 3)
									return '<span class="badge badge-danger">Retur Claim ditolak</span>';
							} else if (row.trackingStatus == 5)
								return '<span class="badge badge-danger">Bermasalah</span>';
						}
					},
				},
        { data: "action",
        sortable: false,
        render: function (data, type, row, meta) {
          return `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
            row.order_no +
            `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`;
        } },
      ],
      filterBy: [0, 1, 2, 4, 5, 6],
      rowCallback: function (row, data) {
        // $("td:eq(0)", row).html(
        //   '<a class="link link-order text-info" style="cursor:pointer;" data-id="' +
        //     data.order_code +
        //     '">' +
        //     data.order_code +
        //     "</a>"
        // );

        // $("td:eq(15)", row).html(
          
        // );
      },
      footerCallback:function ( row, data, start, end, display ){
        var api = this.api(), data;
            // Total over this page
            var nilaicod;
            nilaicod = 
				api
                .column(11)
                .data()
				.reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0);

          //   var ongkir;
          //   ongkir = api
          //       .column(12)
          //       .data()
          //       .reduce( function (a, b) {
					// var x = parseFloat( a ) || 0;
					// var y = parseFloat( b ) || 0;
					// return x + y;
          //       }, 0 );

            var ongkir;
            ongkir = api
                .column(14)
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            var biayacod;
            biayacod = api
                .column(17)
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            var grandtotal;
            grandtotal = api
                .column(18)
                .data()
                .reduce( function (a, b) {
					var x = parseFloat( a ) || 0;
					var y = parseFloat( b ) || 0;
					return x + y;
                }, 0 );

            // Update footer
        $( api.column(11).footer() ).html(
        'Rp'+ formatCurrency(nilaicod)
				);
				$( api.column(14).footer() ).html(
					'Rp'+ formatCurrency(ongkir)
				);
				$( api.column(17).footer() ).html(
					'Rp'+ formatCurrency(biayacod)
				);
				$( api.column(18).footer() ).html(
					'Rp'+ formatCurrency(grandtotal)
				);
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
      }
    });
  },
};
</script>
<style scoped>
th {
  /* text-align: center; */
  /* vertical-align: middle; */
  white-space: nowrap;
}
.dataTables_length{padding-top: 0.5em;}
</style>